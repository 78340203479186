import React, {useEffect} from 'react'
import styles from './before-after.module.scss'
import infographic from './infographic.webp'
import infographicAfter from './infographic-after.webp'
import beforeOnButton from './before-on.png'
import beforeOffButton from './before-off.png'
import afterOnButton from './after-on.png'
import afterOffButton from './after-off.png'

export default function BeforeAfter() {

  const [beforeAfterState, setBeforeAfterState] = React.useState(true);
  const [hasClicked, setHasClicked] = React.useState(false);
  const setBefore = () => {
    setBeforeAfterState(true);
    setHasClicked(true);
  }
  const setAfter = () => {
    setBeforeAfterState(false);
    setHasClicked(true);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log('beforeAfterState', beforeAfterState);
      setBeforeAfterState(prevState => {
        if (hasClicked) {
          return prevState; // Do not toggle if hasClicked is true
        }
        return !prevState; // Toggle the state
      });
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [beforeAfterState, hasClicked]);

  return (
    <div className={styles.embarkPanel}>
      <div className={styles.embarkTitle}>Embark on the journey to greener events</div>
      <div className={styles.embarkText}>The Corpus can help reduce the carbon emissions from your events. Working with your teams, we can calculate a benchmark and advise on strategic changes to decrease the overall carbon emissions in future. Taking the next step together could result in a 50% reduction in emissions of your next event. </div>
      <div className={styles.beforeAfterButtons}>
        <div className={styles.beforeButton}><a onClick={setBefore}><img style={{'maxWidth': '100%'}} src={beforeAfterState ? beforeOnButton : beforeOffButton}/></a></div>
        <div className={styles.afterButton}><a onClick={setAfter} className={styles.afterButton}><img style={{'maxWidth': '100%'}}  src={beforeAfterState ? afterOffButton : afterOnButton}/></a></div>
      </div>
      <div className={styles.imageHolder}>
        <img className={styles.infographic + ' ' + (beforeAfterState ? styles.fadeInInfographic : '')} src={infographic} alt="Infographic"/>
        <img className={styles.infographic + ' ' + styles.infographicAfter + ' ' + (!beforeAfterState ? styles.fadeInInfographic : '')} src={infographicAfter} alt="Infographic"/>
      </div>
    </div>
  )
}
