import React, {useContext, useEffect} from 'react'
import styles from './contact-form.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import SelectedSectionContext from "../contexts/selected-section-context-manager";

export default function ContactForm() {

  const [selectedButtons, setSelectedButtons] = React.useState([]);
  const [showButton, setShowButton] = React.useState(true);
  const selectedSection = useContext(SelectedSectionContext);


  let [showForm, setShowForm] = React.useState(true);
  const buttonClick = (e, value) => {
    if(e) {
      e.preventDefault();
    }
    setSelectedButtons(prevSelectedButtons => {
      // Check if the buttonId is already included in the array
      const index = prevSelectedButtons.indexOf(value);
      if (index > -1) {
        // If found, remove it from the array
        return prevSelectedButtons.filter(item => item !== value);
      } else {
        // Otherwise, add it to the array
        return [...prevSelectedButtons, value];
      }
    });
  }

  useEffect(() => {
    console.log('effecty', selectedSection);
    if(selectedSection) {
      buttonClick(null, selectedSection.selectedSection);
    }
  }, [selectedSection]);
  const submitForm = (e) => {
    let form = document.getElementById('corpusGreenForm');
    e.preventDefault();
    if (!form[5].checkValidity() || !form[6].checkValidity()) {
      form[5].reportValidity();
      form[6].reportValidity();
      return
    }
    setShowButton(false);
    let name = document.getElementById('name').value;
    let email = document.getElementById('email').value;
    let interest = 'Interests: ' + selectedButtons.join(', ');
    let message = interest + ', Content: ' + document.getElementById('message').value;

    let data = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }

    data.name = name;
    data.message = message;
    data.subject = 'Corpus Green Enquiry';
    data.email = email;

    fetch('https://krlzbkee6g.execute-api.eu-west-1.amazonaws.com/Prod/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'Rcx3EXvsOi465EV4PPk8y7okv71mbR058Sjy2kdb',
        'accept': '*/*',
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // or response.text() if the response is not in JSON format
      })
      .then(data => {
        setShowForm(false);
        console.log('Success:', data);
      })
      .catch(error => {
        alert('Sorry, there was a problem submitting your form. Please contact us via the email address above the form.')
        console.error('Error:', error);
      });
  }

  return (
    <>
      {showForm &&
        <div className={styles.formWrapper}>
          <p className={styles.formTitle}>I'm interested in...</p>
          <form id={'corpusGreenForm'} action="https://formspree.io/f/mleqpzko" method="POST">
            <Container>
              <Row>
                <Col style={{'padding': '0px'}} sm={12} md={6}>

                  <button value={'audit'} onClick={(event) => buttonClick(event,'audit')} className={`${styles.formButton} ${selectedButtons.includes('audit') ? styles.formButtonSelected : ''}`}>
                    Audit for sustainability
                  </button>
                </Col>
                <Col style={{'padding': '0px'}} sm={12} md={6}>
                  <button value={'strategy'} onClick={(event) => buttonClick(event,'strategy')} className={`${styles.formButton} ${selectedButtons.includes('strategy') ? styles.formButtonSelected : ''}`}>
                    Sustainable event strategies
                  </button>
                </Col>
              </Row>
              <Row>
                <Col style={{'padding': '0px'}} sm={12} md={6}>
                  <button value={'event-planning'} onClick={(event) => buttonClick(event,'event-planning')} className={`${styles.formButton} ${selectedButtons.includes('event-planning') ? styles.formButtonSelected : ''}`}>
                    Green event planning
                  </button>
                </Col>
                <Col style={{'padding': '0px'}} sm={12} md={6}>
                  <button value={'transition'} onClick={(event) => buttonClick(event,'transition')} className={`${styles.formButton} ${selectedButtons.includes('transition') ? styles.formButtonSelected : ''}`}>
                    Transition to virtual
                  </button>
                </Col>
              </Row>
              <Row>
                <Col style={{'padding': '0px'}} sm={12} md={12}>
                  <button onClick={() => buttonClick('general')} className={[styles.formButton, styles.fullWidth].join(' ')}>
                    General enquiry
                  </button>
                </Col>
              </Row>
            </Container>
            <table style={{'marginTop': '30px'}} className={styles.formTable}>
              <tbody>
              <tr style={{'marginTop': '40px'}}>
                <td colSpan={2}>
                  <input id={'name'} required type="text" placeholder="Your name"></input>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{'height': '20px'}}>

                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <input id={'email'} required type="email" placeholder="Your email"></input>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{'height': '20px'}}>

                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <textarea id={'message'} placeholder={'Your message'}></textarea>
                </td>
              </tr>
              </tbody>
            </table>

            <button disabled={!showButton} type={'submit'} onClick={submitForm} className={styles.submitButton}>Send Message</button>

          </form>
        </div>
      }
      {!showForm &&
        <div className={styles.formWrapper} style={{'height': '440px'}}>
          <p className={styles.formTitle}>Thank you for your enquiry. We will be in touch shortly.</p>
        </div>
      }
    </>
  )
}
