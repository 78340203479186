import React from 'react';
import styles from './header.module.scss';
import { Button, Col, Container, Row } from "react-bootstrap";

export default function Header() {
  return (
    <div className={styles.header}>
      <Container className="h-100">
        <Row className="align-items-center h-100">
          {/* Logo Column: Uses flex-grow on mobile to center the logo */}
          <Col xs={12} md={4} lg={3} className="d-flex justify-content-md-start justify-content-center px-md-0">
            <img src={'./images/corpus-logo.png'} className={styles.corpusLogo} alt="Corpus Logo"/>
          </Col>

          {/* Spacer Column: Takes up remaining space */}
          <Col md={4} lg={6} className="d-none d-md-block"></Col>

          {/*/!* Button Column: Always aligned to the right *!/*/}
          {/*<Col xs={12} md={4} lg={3} className="d-flex align-items-center justify-content-end px-md-0">*/}
          {/*  <Button variant="primary">Click Me</Button>*/}
          {/*</Col>*/}
        </Row>
      </Container>
    </div>
  )
}
