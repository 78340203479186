import React from 'react';
import Header from "../header/header";
import Section1 from "../section-1/section-1";
import Section2 from "../section-2/section-2";
import Footer from "../footer/footer";
import SelectedSectionContext from "../contexts/selected-section-context-manager";

function HomePage() {
  const [selectedSection, setSelectedSection] = React.useState('');

  const onSectionSelected = (section) => {
    console.log('1', section);
    setSelectedSection(section);
  }
  return (
    <div>
      <SelectedSectionContext.Provider value={{selectedSection: selectedSection, setSelectedSection: setSelectedSection}}>
        <Header></Header>
        <Section1 onSectionSelected={onSectionSelected}></Section1>
        <Section2></Section2>
        <Footer></Footer>
      </SelectedSectionContext.Provider>
    </div>
  );
}

export default HomePage;
