import React from 'react'
import styles from './section-1.module.scss'
import {Col, Container, Row} from "react-bootstrap";
import BeforeAfter from "../before-after/before-after";

export default function Section1({
    onSectionSelected
                                 }) {
  function scrollToSection(sectionId, selection) {
    const section = document.getElementById(sectionId);
    if (section) {
      if(selection) {
        onSectionSelected(selection);
      }
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  return (
    <div className={styles.mainSection}>
      <div className={styles.top}>
        <h2>Greener Solutions for Pharma Events</h2>
        <h3>Empowering Pharma for a Greener Tomorrow</h3>
        <div className={styles.introText}>
          The Corpus is merging innovation with sustainability to revolutionise the way
          pharmaceutical events are run. We focus on reducing carbon footprints and
          enhancing environmental stewardship, and offer comprehensive solutions
          tailored to meet your specific needs.
        </div>
      </div>
      <Container className={styles.buttonContainer}>
        <Row>
          <Col sm={12} md={3} className='p-0'>
            <button onClick={() => scrollToSection('section1')} className={styles.greenButton}><span>Audit for </span><span>sustainability</span></button>
          </Col>
          <Col sm={12} md={3} className='p-0'>
            <button onClick={() => scrollToSection('section2')} className={styles.greenButton}><span>Greener event </span><span>strategies</span></button>
          </Col>
          <Col sm={12} md={3} className='p-0'>
            <button onClick={() => scrollToSection('section3')} className={styles.greenButton}><span>Green event </span><span>planning</span></button>
          </Col>
          <Col sm={12} md={3} className='p-0'>
            <button onClick={() => scrollToSection('section4')} className={styles.greenButton}><span>Transition to </span><span>virtual</span></button>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className='p-0'>
            <div>
             <BeforeAfter></BeforeAfter>
              {/*<div className={styles.embarkTitle}>Embark on the journey to greener events</div>*/}
              {/*<img src={'../images/infographic.png'} alt="Infographic"/>*/}
            </div>
          </Col>
        </Row>
        <Row id={'section1'}>
          <Col sm={12} className='p-0'>
            <div className={styles.greenInfoPanel}>
              <div className={`${styles.photo1} ${styles.photoPanelLeft}`}>
                <div className={styles.triangleLeft}></div>
              </div>
              <div className={styles.infoPanelRight}>
                <div className={styles.greenTitle}>Assessing Impact, Paving
                  the Way for Change</div>
                <div className={styles.spacer}></div>
                <div className={styles.greenSubtitle}>Audit for Sustainability</div>
                <div className={styles.greenMainText}>
                  The Corpus will thoroughly audit your previous
                  live and in-person events, providing detailed
                  insights into their carbon footprint. By
                  understanding the environmental impact of your
                  past endeavours, we help you pave the way for
                  meaningful change and sustainable progress.
                </div>
                <div>
                  <a onClick={() => scrollToSection('theForm', 'audit')} className={`btn ${styles.btnCorpus}`}>Request an audit</a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row id={'section2'}>
          <Col sm={12} className='p-0'>
            <div className={styles.greenInfoPanel}>
              <div className={styles.infoPanelLeft}>
                <div className={styles.greenTitle}>Redefining Excellence,
                  Minimising Impact</div>
                <div className={styles.spacer}></div>
                <div className={styles.greenSubtitle}>Green Event Strategies</div>
                <div className={styles.greenMainText + ' ' + styles.extendedWidth}>
                  Discover a spectrum of innovative strategies
                  tailored to your events, aimed at reducing
                  carbon footprints without compromising on
                  quality or impact. From logistics to materials, we
                  suggest improvements that align with your
                  goals and values, fostering a culture of
                  sustainability within your organisation.
                </div>
                <div>
                  <a onClick={() => scrollToSection('theForm', 'strategy')} className={`btn ${styles.btnCorpus}`}>Find out more</a>
                </div>
              </div>
              <div className={`${styles.photo2} ${styles.photoPanelRight}`}>
                <div className={styles.triangleRight}></div>
              </div>
            </div>
          </Col>
        </Row>
        <Row id={'section3'}>
          <Col sm={12} className='p-0'>
            <div className={styles.greenInfoPanel}>
              <div className={`${styles.photo3} ${styles.photoPanelLeft}`}>
                <div className={styles.triangleLeft}></div>
              </div>
              <div className={styles.infoPanelRight}>
                <div className={styles.greenTitle}>Crafting Tomorrow's Events,
                  Today</div>
                <div className={styles.spacer}></div>
                <div className={styles.greenSubtitle}>Green Event Planning</div>
                <div className={styles.greenMainText}>
                  Let us take the reins for your next event, ensuring
                  it's not just memorable but also environmentally
                  responsible. Our dedicated team will
                  meticulously plan and execute every detail, from
                  venue selection to transportation, with a
                  commitment to sustainability at the forefront.
                </div>
                <div>
                  <a onClick={() => scrollToSection('theForm', 'event-planning')} className={`btn ${styles.btnCorpus}`}>Plan your green event</a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row id={'section4'}>
          <Col sm={12} className='p-0'>
            <div className={styles.greenInfoPanel}>
              <div className={styles.infoPanelLeft}>
                <div className={styles.greenTitle}>Embrace the Future, Minimize
                  Footprint</div>
                <div className={styles.spacer}></div>
                <div className={styles.greenSubtitle}>Transition to Virtual Events</div>
                <div className={styles.greenMainText + ' ' + styles.extendedWidth}>
                  Transition your events to the digital sphere with
                  confidence, knowing you're making a significant
                  impact on reducing carbon emissions. Our
                  seamless online event solutions offer
                  unparalleled engagement while minimizing
                  environmental footprint, allowing you to
                  connect with your audience in a more sustainable way..
                </div>
                <div>
                  <a onClick={() => scrollToSection('theForm', 'transition')} className={`btn ${styles.btnCorpus}`}>Contact us to find out how</a>
                </div>
              </div>
              <div className={`${styles.photo4} ${styles.photoPanelRight}`}>
                <div className={styles.triangleRight}></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
