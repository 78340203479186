import './fonts.css';
import './App.module.scss';
import HomePage from "./home-page/home-page";

function App() {
  return (
    <div className="App">
      <HomePage></HomePage>
    </div>
  );
}

export default App;
