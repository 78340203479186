import React from 'react'
import styles from './footer.module.scss'

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.link}>
        <a href={'./privacy.html'} rel="noreferrer" target={'_blank'}>Privacy Policy</a>
      </div>
      <div className={styles.link}>
        <a href={'./terms.html'} rel="noreferrer" target={'_blank'}>Terms and Conditions</a>
      </div>
    </div>
  )
}
