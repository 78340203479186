import React from 'react'
import styles from './section-2.module.scss';
import {Container, Row} from "react-bootstrap";
import ContactForm from "../contact-form/contact-form";

export default function Section2() {
  return (
    <div className={styles.bottomSection}>
      <div className={`${styles.greenSection} ${styles.green}`}>
        <Container>
          <Row className={styles.greenRow}>
            <h2>Why Go Green?</h2>
            <p>
              In an era where environmental consciousness is paramount, adopting sustainable practices isn't just a
              choice—it's
              a responsibility. It's about safeguarding our planet and the health of future generations. By embracing
              sustainable
              practices, pharmaceutical companies play a pivotal role in preserving ecosystems, conserving resources,
              and
              mitigating climate change.
            </p>
            <p>
              Every decision made in the pharmaceutical industry ultimately impacts patients' lives. By prioritizing
              sustainability and making greener choices, companies demonstrate their commitment to not only delivering life-changing medications
              but
              also safeguarding the environment upon which human health depends. Sustainable practices ensure the
              longevity of resources vital for drug development, securing a sustainable future for healthcare.
            </p>
            <p>
              By integrating sustainability into every aspect of operations, companies not only protect the planet but
              also
              uphold their commitment to patient well-being, trust, innovation, and collaboration. Together, let's pave
              the way
              for a healthier, more sustainable future in healthcare.
            </p>
          </Row>
        </Container>
      </div>
      <div className={`${styles.greenSection} ${styles.blueGreen}`}>
        <Container id={'theForm'}>
          <Row className={styles.greenRow}>
            <h2>Ready to embark on a journey towards
              greener events?</h2>
            <p>
              Ready to embark on a journey towards greener events? Contact us today to learn more about our more sustainable solutions tailored for pharmaceutical companies. Together, let's make a lasting impact on our planet while advancing your organizational goals.
            </p>
            <p><a href={'mailto:communications@the-corpus.com'}>communications@the-corpus.com</a></p>
            <p>
              +44 (0)20 7428 2903
            </p>
            <ContactForm></ContactForm>
          </Row>
        </Container>
      </div>
    </div>
  )
}
